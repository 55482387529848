
import { defineComponent } from 'vue'
import Papa from 'papaparse'
import ContactForm from '@/components/particles/forms/ContactForm/ContactForm.vue'

export default defineComponent({
  props: {
    activeTab: {
      type: Number,
      default: () => 0,
    },
    modalCompanyList: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ContactForm,
  },
  emits: ['close', 'onAddContact'],

  setup(props, { emit }) {
    const onAddContactInfo = (addContact: any) => {
      emit('onAddContact', addContact)
    }
    const dragFile = (e: any) => {
      Papa.parse(e.dataTransfer.files[0], {
        complete: (results: any) => {
          if (!results.errors.length) {
            // save imported data
            // console.info(results.data);
          }
        },
      });
    };
    return {
      dragFile,
      onAddContactInfo,
    };
  },
})
