<template>
    


    <div :class="{
        'flex space-x-4': variant === 'horizontal',
    }">
        <ul class="list-none bg-blue-900 bg-opacity-30 p-1.5 rounded-sm text-center overflow-auto whitespace-nowrap"
            :class="{
                'flex items-center mb-6': variant === 'vertical',
            }">
            <li v-for="(tab) in tabList" :key="tab.key" class="w-3/12 px-4 py-1.5 rounded-sm" :class="{
                'text-white bg-blue ': tab.key + 1 === activeTab,
                'text-grey bg-white ': tab.key + 1 !== activeTab,
            }">
                <label :for="`${_uid}${tab.key}`" v-text="tab.name" class="cursor-pointer block" />
                <input :id="`${_uid}${tab.key}`" type="radio" :name="`${_uid}-tab`" :value="tab.key + 1"
                    v-model="activeTab" class="hidden" />
            </li>


            <div v-if="enableButton" class="flex items-center justify-between gap-x-4">
                <div></div>
                <div class="flex items-center justify-between">
                    <UiButton class="real-estate-page--new-button bg-blue py-3 px-4 text-white hover:bg-darkBlue"
                        @click="showModal(activeTab)">
                        <UiIcon name="plus" class="mr-1.5 " />
                        {{ $t('CONTACT_BUTTON_NEW_ITEM') }}
                    </UiButton>
                </div>
            </div>
            
        </ul>
        <Filter v-if="enableButton"  class="mb-5" v-model:searchRequestOne="livingHouses" v-model:searchRequestTwo="livingObjects" @onFilter="handleFilter" />
        <template v-for="(tab) in tabList">
            <div :key="tab.key" v-if="tab.key + 1 === activeTab" class="flex-grow  rounded-sm  ">
                <slot :name="`tabPanel-${tab.key + 1}`" />
            </div>
        </template>
    </div>
    
</template>
  
<script>


import Filter from '@/components/particles/ContactView/Filter.vue'

export default {
    components: {
    Filter,
  },
    props: {
        enableButton: {
            type: Boolean,
            default: () => false,
        },
        tabList: {
            type: Array,
            required: true,
        },
        variant: {
            type: String,
            required: false,
            default: () => "vertical",
            validator: (value) => ["horizontal", "vertical"].includes(value),
        },
    },
    emits: ['toggleAddModal'],
    data(props) {
        return {
            activeTab: props.tabList[0].key+1,
        };
    },
    setup(props, { emit }) {
    const showModal = (activeTab) => {
      emit('toggleAddModal', activeTab)
    }
    return { showModal }
    }
};
</script>