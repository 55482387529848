
import { defineComponent, ref, computed } from 'vue'
import { useCancelToken } from '@/hooks/useCancelToken'
import useStore from '@/store'
import { CONTACT_ACTION_TYPES } from '@/store/contact/actions'
import { COMPANY_ACTION_TYPES } from '@/store/company/actions'
import { USER_SETTINGS_ACTION_TYPES } from '@/store/settings/actions'
import LoadingSpinner from '@/components/particles/common/LoadingSpinner.vue'
import ViewLayout from '@/components/particles/common/ViewLayout.vue'
import OrganismTable from '@/components/particles/ContactView/OrganismTable.vue'
import AdminTable from '@/components/particles/ContactView/AdminTable.vue'
import GuardianTable from '@/components/particles/ContactView/GuardianTable.vue'
import CompanyTable from '@/components/particles/ContactView/CompanyTable.vue'
import TechTable from '@/components/particles/ContactView/TechTable.vue'
import { guardianTableHeaders, adminTableHeaders, organismTableHeaders, companyTableHeaders, techTableHeaders } from '@/helpers/tableHeaders'
import ContactModal from '@/components/particles/ContactView/ContactModal.vue'
import ContactUpdateModal from '@/components/particles/ContactView/ContactUpdateModal.vue'
import AppTabs from "@/components/UI/Tabs/AppTab.vue";
import { IContact } from '@/store/contact/types'
import { createToast } from 'mosha-vue-toastify';

import { sleep } from '@/helpers/utils'


export default defineComponent({
  name: 'ContactView',
  components: {
    LoadingSpinner,
    ViewLayout,
    OrganismTable,
    AdminTable,
    GuardianTable,
    CompanyTable,
    TechTable,
    ContactModal,
    ContactUpdateModal,
    AppTabs
  },
  setup() {
    const store = useStore()
    const getContactCancelToken = useCancelToken()
    const getCompanyCancelToken = useCancelToken()
    const getPersonalInfoCancelToken = useCancelToken()
    const headerFirstname = ref()
    const headerLastname = ref()
    const headerPhone = ref()
    const headerEmail = ref()
    const headerAddDate = ref()
    const perPage = ref()
    const contactData = ref()
    let isShowAddModal = ref(false)
    let isShowUpdateModal = ref(false)
    let activeTabRef = ref()
    let modalCompanyList = ref()



    const guardianList = computed(() => store.state.contact.guardianInfo)
    const managerList = computed(() => store.state.contact.managerInfo)
    const technicianList = computed(() => store.state.contact.technicianInfo)
    const companyList = computed(() => store.state.company.companyInfo)
    const isLoaded = computed(() => store.state.contact.isLoaded)
    const personalData = computed(() => store.state.userSettings.personalInfo)

    const isError = computed(() => {
      if (store.state.userSettings.isError) {
        createToast({
          title: 'Une erreur est survenue',
          description: store.state.userSettings.error
        },
          {
            type: 'danger',
            position: 'bottom-right',
            showIcon: true,
          })
        store.dispatch(
          CONTACT_ACTION_TYPES.RESET_ERROR_STATE,
          getContactCancelToken.value
        )
      }
      return store.state.userSettings.isError
    })

    const toggleAddModal = (activeTab: any) => {
      activeTabRef.value = activeTab
      if (activeTab === 4) {
        modalCompanyList.value = companyList.value.map((item: any) => {
          return {
            id: item.id,
            name: item.name
          }
        })
      }
      if (activeTab === 5) {
        modalCompanyList.value = companyList.value.map((item: any) => {
          return {
            id: item.id,
            name: item.name
          }
        })
      }
      if (activeTab === 7) {
        modalCompanyList.value = companyList.value.map((item: any) => {
          return {
            id: item.id,
            name: item.name
          }
        })
      }
      isShowAddModal.value = !isShowAddModal.value
    }

    const toggleUpdateModal = (contact: any) => {
      contactData.value = contact
      if (activeTabRef.value === 4) {
        modalCompanyList.value = companyList.value.map((item: any) => {
          return {
            id: item.id,
            name: item.name
          }
        })
      }
      if (activeTabRef.value === 5) {
        modalCompanyList.value = companyList.value.map((item: any) => {
          return {
            id: item.id,
            name: item.name
          }
        })
      }
      if (activeTabRef.value === 7) {
        modalCompanyList.value = companyList.value.map((item: any) => {
          return {
            id: item.id,
            name: item.name
          }
        })
      }
      isShowUpdateModal.value = !isShowUpdateModal.value
    }

    const getCompanyInfo = async () => {
      try {
        await Promise.all([
          store.dispatch(
            COMPANY_ACTION_TYPES.GET_COMPANY_INFO,
            getCompanyCancelToken.value
          ),
        ])
      } catch (error) {
        console.log('error: ', error)
      }
    }

    const getContactInfo = async () => {
      try {
        await Promise.all([
          store.dispatch(
            CONTACT_ACTION_TYPES.GET_CONTACT_INFO,
            getContactCancelToken.value
          ),
        ])
      } catch (error) {
        console.log('error: ', error)
      }
    }

    const onUpdateContactInfo = async (updatedContact: IContact) => {
      await store.dispatch(
        CONTACT_ACTION_TYPES.PATCH_ONE_CONTACT_INFO,
        updatedContact
      )
      toggleUpdateModal(0)
      getContactInfo()
      createToast({
        title: "L'utilisateur a été modifié",
      },
        {
          type: 'success',
          position: 'bottom-right',
          showIcon: true,
        })
    }

    const onAddContact = async (Contact: IContact) => {
      await store.dispatch(
        CONTACT_ACTION_TYPES.ADD_CONTACT_INFO,
        Contact
      )
      createToast("L'utilisateur a été modifié",
        {
          position: 'bottom-right',
          type: 'success',
          transition: 'bounce',
        })
    }
    const getPersonalInfo = async () => {
      await store.dispatch(
        USER_SETTINGS_ACTION_TYPES.GET_PERSONAL_INFO,
        getPersonalInfoCancelToken.value
      )
    }
    getPersonalInfo()
    getCompanyInfo()
    getContactInfo()

    return {
      guardianList,
      managerList,
      technicianList,
      companyList,
      perPage,
      isLoaded,
      headerFirstname,
      headerLastname,
      headerPhone,
      headerEmail,
      headerAddDate,
      guardianTableHeaders,
      adminTableHeaders,
      organismTableHeaders,
      companyTableHeaders,
      techTableHeaders,
      isShowAddModal,
      isShowUpdateModal,
      onUpdateContactInfo,
      onAddContact,
      toggleAddModal,
      toggleUpdateModal,
      personalData,
      contactData,
      modalCompanyList,
      activeTabRef,
      isError,
      tabListMain: [{ name: "Bailleur", key: 0 }, { name: "Entreprise de maintenance", key: 1 }],
      tabListBailleur: [{ name: "Organisme", key: 2 }, { name: "Gestionnaire", key: 3 }, { name: "Gardien", key: 4 }],
      tabListEntreprise: [{ name: "Entreprise", key: 5 }, { name: "Technicien", key: 6 }],
    }
  },
})

