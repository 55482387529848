
import { defineComponent, reactive, watch } from 'vue'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
  Field
} from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { MIN_PHONE_LENGTH } from '@/constants'

export default defineComponent({
  name: 'ContactForm',
  props: {
    activeTab: {
      type: Number,
      default: () => 0,
    },
    asteriskOnRequired: {
      type: Boolean,
      default: false,
    },   
    contactData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', }),
    },     
    personalData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', roles: ['ROLE_USER'], active: false }),
    },
    modalCompanyList: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Field },
  emits: ['onContactFormSubmit'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const { handleSubmit } = useForm({
      validationSchema: {
        firstname: 'alpha|required',
        lastname: 'alpha|required',
        email: 'required|email',
        phoneNumber: `min:${MIN_PHONE_LENGTH}|numeric`,
      },
    })

    const form = reactive({
      firstname: useField('firstname', '', {
        label: t('CONTACT_MY_INFORMATION_NAME_FIELD_LABEL'),
      }),
      lastname: useField('lastname', '', {
        label: t('CONTACT_MY_INFORMATION_SURNAME_FIELD_LABEL'),
      }),
      email: useField('email', '', {
        label: t('CONTACT_MY_INFORMATION_EMAIL_FIELD_LABEL'),
        validateOnValueUpdate: true,
      }),
      phoneNumber: useField('phoneNumber', '', {
        label: t('CONTACT_MY_INFORMATION_PHONE_FIELD_LABEL'),
      }),
      company: useField('company', '', {
        label: t('CONTACT_MY_INFORMATION_PHONE_FIELD_LABEL'),
      })
    })

    //To set initial Values of the fields, because initialValue in useField doesn't work as expected
    watch(
      () => props.contactData,
      (newVal) => {
        form.firstname.setValue(newVal.firstname)
        form.lastname.setValue(newVal.lastname)
        form.email.setValue(newVal.email)
        form.phoneNumber.setValue(newVal.phoneNumber)
        form.company.setValue(newVal.company)
      },
      { immediate: true }
    )

    const onSubmit = handleSubmit(
      async ({ firstname, lastname, email, phoneNumber,company }) => {
        emit('onContactFormSubmit', { firstname, lastname, email, phoneNumber, company: props.contactData.company , id: props.contactData.id, userOrganization: props.contactData.userOrganization, updatedBy: props.personalData.id, })
      }
    )

    return {
      onSubmit,
      form,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
    }
  },
})
