import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-3xl py-5 max-h-40 mx-auto overflow-y-auto" }
const _hoisted_2 = { class: "flex justify-between gap-x-5" }
const _hoisted_3 = { class: "px-5 py-3 rounded-sm bg-lightGrey flex-1" }
const _hoisted_4 = { class: "flex-1 mb-1 text-base font-medium text-darkGrey" }
const _hoisted_5 = { class: "px-5 py-3 rounded-sm bg-lightGrey flex-1" }
const _hoisted_6 = { class: "mb-1 text-base font-medium text-darkGrey" }
const _hoisted_7 = { class: "px-5 py-3 rounded-sm bg-lightGrey flex-1" }
const _hoisted_8 = { class: "mb-1 text-base font-medium text-darkGrey" }
const _hoisted_9 = { class: "px-5 py-3 rounded-sm bg-lightGrey flex-1" }
const _hoisted_10 = { class: "mb-1 text-base font-medium text-darkGrey" }
const _hoisted_11 = { class: "text-darkGrey text-base mr-2.5" }
const _hoisted_12 = { class: "text-darkGrey text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiNativeSelect = _resolveComponent("UiNativeSelect")!
  const _component_UiTable = _resolveComponent("UiTable")!

  return (_openBlock(), _createBlock(_component_UiTable, {
    headers: _ctx.headers,
    data: _ctx.tableInfo,
    "per-page": _ctx.numberOfVisibleRows,
    ref: "techTable"
  }, {
    "cell:Battery": _withCtx(({ cell }) => [
      _createElementVNode("span", {
        class: _normalizeClass(["inline-block rounded-lg mr-1 w-1.5 h-1.5", 
          _ctx.deleteLetters(cell) >= _ctx.MONITORING_TABLE_BATTERY_LEVEL_HIGH
            ? 'bg-green'
            : _ctx.deleteLetters(cell) <= _ctx.MONITORING_TABLE_BATTERY_LEVEL_LOW
            ? 'bg-red'
            : 'bg-orange'
        ])
      }, null, 2),
      _createTextVNode(" " + _toDisplayString(cell), 1)
    ]),
    rowHiddenInfo: _withCtx(({ row }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('MONITORING_TABLE_HIDDEN_INFO_LAST_ALERT')), 1),
            _createElementVNode("p", null, _toDisplayString(row.Additional.LastAlert), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('MONITORING_TABLE_HIDDEN_INFO_SITE_CODE')), 1),
            _createElementVNode("p", null, _toDisplayString(row.Additional.SiteCode), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('MONITORING_TABLE_HIDDEN_INFO_POSTAL_CODE')), 1),
            _createElementVNode("p", null, _toDisplayString(row.Additional.PostalCode), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('MONITORING_TABLE_HIDDEN_INFO_OBJECT')), 1),
            _createElementVNode("p", null, _toDisplayString(row.Additional.ObjectName), 1)
          ])
        ])
      ])
    ]),
    selectRows: _withCtx(() => [
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_SHOW_ROWS')), 1),
      _createVNode(_component_UiNativeSelect, {
        "model-value": _ctx.numberOfVisibleRows,
        options: [10, 20],
        class: "text-black mr-2.5",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:numberOfVisibleRows', $event)))
      }, null, 8, ["model-value"]),
      _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_SHOW_ROWS_OF')) + " " + _toDisplayString(_ctx.tableInfo.length), 1)
    ]),
    _: 1
  }, 8, ["headers", "data", "per-page"]))
}