
import { defineComponent, ref, PropType } from 'vue'

import Papa from 'papaparse'
import FileSaver from 'file-saver'
import { get, omit } from 'lodash'


import {
  ContactInfoResponse,
  IContact,
} from '@/store/contact/types'
import {
  MONITORING_TABLE_BATTERY_LEVEL_HIGH,
  MONITORING_TABLE_BATTERY_LEVEL_LOW,
} from '@/constants'

export default defineComponent({
  props: {
    tableInfo: {
      type: Array as PropType<IContact[]>,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headerLessor: {
      type: String,
      required: true,
      default: '',
    },
    headerLastname: {
      type: String,
      required: true,
      default: '',
    },
    headerFirstname: {
      type: String,
      required: true,
      default: '',
    },
    headerEmail: {
      type: String,
      required: true,
      default: '',
    },
    headerPhone: {
      type: Function,
      required: true,
      default: (val: unknown) => val,
    },
    headerAddDate: {
      type: Function,
      required: true,
      default: (val: unknown) => val,
    },
    numberOfVisibleRows: {
      type: Number,
      default: 10,
    },
  },
  emits: [
    'update:headerLessor',
    'update:headerLastname',
    'update:headerFirstname',
    'update:headerPhone',
    'update:headerEmail',
    'update:headerAddDate',
    'update:numberOfVisibleRows',
  ],

  setup(props) {
    const monitoringTable = ref(null)
    console.log(props.tableInfo)
    //Leave only types of objects in array => delete duplicate values
    const filteredSelectOptions = (optionToFilterBy: string) => {
      return [
        ...new Set(
          props.tableInfo.map((value: any) => value[optionToFilterBy])
        ),
      ]
    }

    // const showHiddenInfoBlock = (row: IContact) => {
    //   //Adds an IsOpened Field which is necessary to show/close hidden block in UiTable

    //   if (row.IsOpened === undefined || row.IsOpened === false)
    //     row.IsOpened = true
    //   else row.IsOpened = false
    // }

    const deleteLetters = (batteryPercents: string) => {
      return parseInt(batteryPercents.replace(/\D/g, ''))
    }

    const exportCSV = () => {
      const filteredData = get(
        Object.assign({}, monitoringTable.value),
        'filteredData',
        []
      )

      const newFilteredData = filteredData.map((value: any) => {
        const tableItem = Object.assign({}, value)

        return {
          ...omit(tableItem, 'Additional'),
          ...tableItem.Additional,
        }
      })

      const csvFile = new Blob([Papa.unparse(newFilteredData)], {
        type: 'text/csv;charset=utf-8',
      })
      FileSaver.saveAs(csvFile, 'monitoring.csv')
    }

    return {
      filteredSelectOptions,
      // MonitoringInfoStatuses,
      // showHiddenInfoBlock,
      deleteLetters,
      MONITORING_TABLE_BATTERY_LEVEL_HIGH,
      MONITORING_TABLE_BATTERY_LEVEL_LOW,
      monitoringTable,
      exportCSV,
    }
  },
})
