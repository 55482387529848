
import { defineComponent, PropType } from 'vue'
import Papa from 'papaparse'
import ContactForm from '@/components/particles/forms/ContactForm/ContactForm.vue'
import { IContact } from '@/store/contact/types'

export default defineComponent({
  props: {
    activeTab: {
      type: Number,
      default: () => 0,
    },
    contactData: {
      type: Object as PropType<IContact>,
      default: () => ({}),
    },    
    personalData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', id: '', roles: ['ROLE_USER'], active: false }),
    },
    modalCompanyList: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ContactForm,
  },
  emits: ['close', 'onUpdateContactInfo'],

  setup(props, { emit }) {
    const onUpdateContactInfo = (updateContact: any) => {
      emit('onUpdateContactInfo', updateContact)
    }
    const dragFile = (e: any) => {
      Papa.parse(e.dataTransfer.files[0], {
        complete: (results: any) => {
          if (!results.errors.length) {
            // save imported data
            // console.info(results.data);
          }
        },
      });
    };
    return {
      dragFile,
      onUpdateContactInfo,
    };
  },
})
