import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactModal = _resolveComponent("ContactModal")!
  const _component_ContactUpdateModal = _resolveComponent("ContactUpdateModal")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_OrganismTable = _resolveComponent("OrganismTable")!
  const _component_AdminTable = _resolveComponent("AdminTable")!
  const _component_GuardianTable = _resolveComponent("GuardianTable")!
  const _component_app_tabs = _resolveComponent("app-tabs")!
  const _component_CompanyTable = _resolveComponent("CompanyTable")!
  const _component_TechTable = _resolveComponent("TechTable")!
  const _component_ViewLayout = _resolveComponent("ViewLayout")!

  return (_openBlock(), _createBlock(_component_ViewLayout, null, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", null, null, 512), [
        [_vShow, _ctx.isError]
      ]),
      _withDirectives(_createVNode(_component_ContactModal, {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleAddModal(0))),
        onOnAddContact: _ctx.onAddContact,
        activeTab: _ctx.activeTabRef,
        "onUpdate:activeTab": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTabRef) = $event)),
        modalCompanyList: _ctx.modalCompanyList,
        "onUpdate:modalCompanyList": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalCompanyList) = $event)),
        personalData: _ctx.personalData,
        "onUpdate:personalData": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.personalData) = $event))
      }, null, 8, ["onOnAddContact", "activeTab", "modalCompanyList", "personalData"]), [
        [_vShow, _ctx.isShowAddModal]
      ]),
      _withDirectives(_createVNode(_component_ContactUpdateModal, {
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleUpdateModal(0))),
        onOnUpdateContactInfo: _ctx.onUpdateContactInfo,
        activeTab: _ctx.activeTabRef,
        "onUpdate:activeTab": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.activeTabRef) = $event)),
        modalCompanyList: _ctx.modalCompanyList,
        "onUpdate:modalCompanyList": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalCompanyList) = $event)),
        contactData: _ctx.contactData,
        "onUpdate:contactData": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.contactData) = $event)),
        personalData: _ctx.personalData,
        "onUpdate:personalData": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.personalData) = $event))
      }, null, 8, ["onOnUpdateContactInfo", "activeTab", "modalCompanyList", "contactData", "personalData"]), [
        [_vShow, _ctx.isShowUpdateModal]
      ]),
      _createVNode(_component_app_tabs, {
        class: "w-12/12 lg:w-12/12 mx-auto mb-16",
        tabList: _ctx.tabListMain
      }, {
        "tabPanel-1": _withCtx(() => [
          _createVNode(_component_app_tabs, {
            class: "w-12/12 lg:w-12/12 mx-auto mb-16",
            tabList: _ctx.tabListBailleur,
            enableButton: true,
            onToggleAddModal: _ctx.toggleAddModal
          }, _createSlots({
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_LoadingSpinner, { class: "h-screen" }, null, 512), [
                [_vShow, _ctx.isLoaded]
              ])
            ]),
            _: 2
          }, [
            (!_ctx.isLoaded)
              ? {
                  name: "tabPanel-3",
                  fn: _withCtx(() => [
                    _createVNode(_component_OrganismTable, {
                      headerFirstname: _ctx.headerFirstname,
                      "onUpdate:headerFirstname": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.headerFirstname) = $event)),
                      headerLastname: _ctx.headerLastname,
                      "onUpdate:headerLastname": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.headerLastname) = $event)),
                      headerPhone: _ctx.headerPhone,
                      "onUpdate:headerPhone": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.headerPhone) = $event)),
                      headerEmail: _ctx.headerEmail,
                      "onUpdate:headerEmail": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.headerEmail) = $event)),
                      tableInfo: _ctx.companyList,
                      headerAddDate: _ctx.headerAddDate,
                      "onUpdate:headerAddDate": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.headerAddDate) = $event)),
                      headers: _ctx.organismTableHeaders,
                      loading: false,
                      numberOfVisibleRows: _ctx.perPage,
                      "onUpdate:numberOfVisibleRows": _cache[14] || (_cache[14] = ($event: any) => (_ctx.perPage = $event)),
                      onToggleUpdateModal: _ctx.toggleUpdateModal
                    }, null, 8, ["headerFirstname", "headerLastname", "headerPhone", "headerEmail", "tableInfo", "headerAddDate", "headers", "numberOfVisibleRows", "onToggleUpdateModal"])
                  ])
                }
              : undefined,
            (!_ctx.isLoaded)
              ? {
                  name: "tabPanel-4",
                  fn: _withCtx(() => [
                    _createVNode(_component_AdminTable, {
                      headerFirstname: _ctx.headerFirstname,
                      "onUpdate:headerFirstname": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.headerFirstname) = $event)),
                      headerLastname: _ctx.headerLastname,
                      "onUpdate:headerLastname": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.headerLastname) = $event)),
                      headerPhone: _ctx.headerPhone,
                      "onUpdate:headerPhone": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.headerPhone) = $event)),
                      headerEmail: _ctx.headerEmail,
                      "onUpdate:headerEmail": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.headerEmail) = $event)),
                      tableInfo: _ctx.managerList,
                      headerAddDate: _ctx.headerAddDate,
                      "onUpdate:headerAddDate": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.headerAddDate) = $event)),
                      headers: _ctx.adminTableHeaders,
                      loading: false,
                      numberOfVisibleRows: _ctx.perPage,
                      "onUpdate:numberOfVisibleRows": _cache[20] || (_cache[20] = ($event: any) => (_ctx.perPage = $event)),
                      onToggleUpdateModal: _ctx.toggleUpdateModal
                    }, null, 8, ["headerFirstname", "headerLastname", "headerPhone", "headerEmail", "tableInfo", "headerAddDate", "headers", "numberOfVisibleRows", "onToggleUpdateModal"])
                  ])
                }
              : undefined,
            (!_ctx.isLoaded)
              ? {
                  name: "tabPanel-5",
                  fn: _withCtx(() => [
                    _createVNode(_component_GuardianTable, {
                      headerFirstname: _ctx.headerFirstname,
                      "onUpdate:headerFirstname": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.headerFirstname) = $event)),
                      headerLastname: _ctx.headerLastname,
                      "onUpdate:headerLastname": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.headerLastname) = $event)),
                      headerPhone: _ctx.headerPhone,
                      "onUpdate:headerPhone": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.headerPhone) = $event)),
                      headerEmail: _ctx.headerEmail,
                      "onUpdate:headerEmail": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.headerEmail) = $event)),
                      tableInfo: _ctx.guardianList,
                      headerAddDate: _ctx.headerAddDate,
                      "onUpdate:headerAddDate": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.headerAddDate) = $event)),
                      headers: _ctx.guardianTableHeaders,
                      loading: false,
                      numberOfVisibleRows: _ctx.perPage,
                      "onUpdate:numberOfVisibleRows": _cache[26] || (_cache[26] = ($event: any) => (_ctx.perPage = $event)),
                      onToggleUpdateModal: _ctx.toggleUpdateModal
                    }, null, 8, ["headerFirstname", "headerLastname", "headerPhone", "headerEmail", "tableInfo", "headerAddDate", "headers", "numberOfVisibleRows", "onToggleUpdateModal"])
                  ])
                }
              : undefined
          ]), 1032, ["tabList", "onToggleAddModal"])
        ]),
        "tabPanel-2": _withCtx(() => [
          _createVNode(_component_app_tabs, {
            class: "w-12/12 lg:w-12/12 mx-auto mb-16",
            tabList: _ctx.tabListEntreprise,
            enableButton: true,
            onToggleAddModal: _ctx.toggleAddModal
          }, _createSlots({
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_LoadingSpinner, { class: "h-screen" }, null, 512), [
                [_vShow, _ctx.isLoaded]
              ])
            ]),
            _: 2
          }, [
            (!_ctx.isLoaded)
              ? {
                  name: "tabPanel-6",
                  fn: _withCtx(() => [
                    _createVNode(_component_CompanyTable, {
                      headerFirstname: _ctx.headerFirstname,
                      "onUpdate:headerFirstname": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.headerFirstname) = $event)),
                      headerLastname: _ctx.headerLastname,
                      "onUpdate:headerLastname": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.headerLastname) = $event)),
                      headerPhone: _ctx.headerPhone,
                      "onUpdate:headerPhone": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.headerPhone) = $event)),
                      headerEmail: _ctx.headerEmail,
                      "onUpdate:headerEmail": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.headerEmail) = $event)),
                      tableInfo: _ctx.companyList,
                      headerAddDate: _ctx.headerAddDate,
                      "onUpdate:headerAddDate": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.headerAddDate) = $event)),
                      headers: _ctx.companyTableHeaders,
                      loading: false,
                      numberOfVisibleRows: _ctx.perPage,
                      "onUpdate:numberOfVisibleRows": _cache[32] || (_cache[32] = ($event: any) => (_ctx.perPage = $event))
                    }, null, 8, ["headerFirstname", "headerLastname", "headerPhone", "headerEmail", "tableInfo", "headerAddDate", "headers", "numberOfVisibleRows"])
                  ])
                }
              : undefined,
            (!_ctx.isLoaded)
              ? {
                  name: "tabPanel-7",
                  fn: _withCtx(() => [
                    _createVNode(_component_TechTable, {
                      headerFirstname: _ctx.headerFirstname,
                      "onUpdate:headerFirstname": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.headerFirstname) = $event)),
                      headerLastname: _ctx.headerLastname,
                      "onUpdate:headerLastname": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.headerLastname) = $event)),
                      headerPhone: _ctx.headerPhone,
                      "onUpdate:headerPhone": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.headerPhone) = $event)),
                      headerEmail: _ctx.headerEmail,
                      "onUpdate:headerEmail": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.headerEmail) = $event)),
                      tableInfo: _ctx.technicianList,
                      headerAddDate: _ctx.headerAddDate,
                      "onUpdate:headerAddDate": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.headerAddDate) = $event)),
                      headers: _ctx.techTableHeaders,
                      loading: false,
                      numberOfVisibleRows: _ctx.perPage,
                      "onUpdate:numberOfVisibleRows": _cache[38] || (_cache[38] = ($event: any) => (_ctx.perPage = $event))
                    }, null, 8, ["headerFirstname", "headerLastname", "headerPhone", "headerEmail", "tableInfo", "headerAddDate", "headers", "numberOfVisibleRows"])
                  ])
                }
              : undefined
          ]), 1032, ["tabList", "onToggleAddModal"])
        ]),
        _: 1
      }, 8, ["tabList"])
    ]),
    _: 1
  }))
}